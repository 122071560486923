import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@mui/material';

import usePriorityTodos from '@/hooks/todos/usePriorityTodos';
import useUserTracking from '@/hooks/useUserTracking';
import { TodoAccountDataModel } from '@/models/Todo';

import { Images } from '../../constants/Images';
import ToDoModal from '../todo-page/ToDoModal';
import Loading from '../utility/Loading';
import SaveWithProBadge from '../utility/statuses/SaveWithProBadge';
import ToDoCategories from '../utility/statuses/ToDoCategories';

const ToDoListCard = () => {
  const { t } = useTranslation();
  const userTracking = useUserTracking();

  const [chosenToDo, setChosenToDo] = useState<TodoAccountDataModel>();
  const navigate = useNavigate();

  const { priorityTodos, priorityTodosIsLoading, refetchPriorityTodos } = usePriorityTodos();

  const handleClick = (item: TodoAccountDataModel) => {
    setChosenToDo(item);
    userTracking?.userOpenedToDo?.(item);
  };

  return (
    <>
      {chosenToDo && (
        <ToDoModal
          todo={chosenToDo}
          resetTodo={() => {
            setChosenToDo(undefined);
          }}
          refreshTodos={refetchPriorityTodos}
        />
      )}
      <div className="card w100p">
        <div className="d-flex jc-space-between mb20">
          <h3 className="">To Do List</h3>
          <p
            className="pt4 cursor-pointer"
            onClick={() => {
              navigate('/to-do-list');
            }}
          >
            {t('COMMON.VIEW-ALL')}
          </p>
        </div>
        {!priorityTodosIsLoading &&
          priorityTodos?.map((item, index: number) => (
            <div
              onClick={() => {
                handleClick(item);
              }}
              key={index}
              className="card-inner min-h115 w100p mb8 list-item-parent"
              style={{ '--animation-number': `${index}` } as React.CSSProperties}
            >
              <div className="d-flex jc-space-between mb10">
                <ToDoCategories category={item.category} />
                <Icon className="text-faded">chevron_right</Icon>
              </div>
              <div className="d-flex list-item">
                <object
                  className="playlist-image mt0"
                  data={item?.content?.images?.home ?? '/images/logos/unhurd-logo.png'}
                  type="image/png"
                >
                  <img className="playlist-image mt0" src={Images.unhurdFallback} alt="Fallback playlist img" />
                </object>
                <div className="flex-grow">
                  {item.category === 'partners' && (
                    <div className="mb8">
                      <SaveWithProBadge />
                    </div>
                  )}
                  <div className="fs-16 fw-bold">{item.content.previewTitle}</div>
                  <p className="text-faded small">{item.content.description}</p>
                </div>
              </div>
            </div>
          ))}
        {!priorityTodosIsLoading && (!priorityTodos || priorityTodos?.length === 0) && (
          <div>No priority to dos yet, check back later</div>
        )}
        {priorityTodosIsLoading && (
          <div className="centered-loading mt100 mb48">
            <Loading size="small" />
          </div>
        )}
      </div>
    </>
  );
};

export default ToDoListCard;
